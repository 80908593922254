<template>
    <div>
        <div class="container-flex mb-3" style="justify-content: space-between;">
            <h2 class="h2-bold">
                Tranascciones
            </h2>
            <b-button size="md" variant="primary" :disabled="user.available <= 0" @click="isWithdrawing = true">
                Solicitar retiro
            </b-button>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-3">
                <b-card class="bg-full">
                    <div class="container-flex">
                        <div class="logo">
                            <img src="@/assets/images/brand.png" style="max-width: 80%;" alt="">
                        </div>
                        <div class="container-items" style="margin: auto 0;">
                            <h6>Disponible USD</h6>
                            <h3>${{ formatCurrency(user.available) }}</h3>
                        </div>
                    </div>
                </b-card>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
                <b-card class="bg-full">
                    <div class="container-flex">
                        <div class="logo">
                            <img src="@/assets/images/brand.png" style="max-width: 80%;" alt="">
                        </div>
                        <div class="container-items" style="margin: auto 0;">
                            <h6>Total USD</h6>
                            <h3>${{ formatCurrency(user.total) }}</h3>
                        </div>
                    </div>
                </b-card>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
                <b-card class="bg-full">
                    <div class="container-flex">
                        <div class="logo">
                            <img src="@/assets/images/brand.png" style="max-width: 80%;" alt="">
                        </div>
                        <div class="container-items" style="margin: auto 0;">
                            <h6>Total Direct</h6>
                            <h3>${{ formatCurrency(user.direct) }}</h3>
                        </div>
                    </div>
                </b-card>
            </div>
            
            <div class="col-12 col-sm-6 col-md-3">
                <b-card class="bg-full">
                    <div class="container-flex">
                        <div class="logo">
                            <img src="@/assets/images/brand.png" style="max-width: 80%;" alt="">
                        </div>
                        <div class="container-items" style="margin: auto 0;">
                            <h6>Total indirect</h6>
                            <h3>${{ formatCurrency(user.indirect) }}</h3>
                        </div>
                    </div>
                </b-card>
            </div>
            <div class="col-12 col-sm-6 col-md-3" v-if="user.is_teacher">
                <b-card class="bg-full">
                    <div class="container-flex">
                        <div class="logo">
                            <img src="@/assets/images/brand.png" style="max-width: 80%;" alt="">
                        </div>
                        <div class="container-items" style="margin: auto 0;">
                            <h6>Total teacher</h6>
                            <h3>${{ formatCurrency(user.teacher) }}</h3>
                        </div>
                    </div>
                </b-card>
            </div>
            <div class="col-12 col-sm-6 col-md-3" v-if="user.is_signal">
                <b-card class="bg-full">
                    <div class="container-flex">
                        <div class="logo">
                            <img src="@/assets/images/brand.png" style="max-width: 80%;" alt="">
                        </div>
                        <div class="container-items" style="margin: auto 0;">
                            <h6>Total Signal</h6>
                            <h3>${{ formatCurrency(user.signal) }}</h3>
                        </div>
                    </div>
                </b-card>
            </div>
            <div class="col-12">
                <b-card class="bg-blur mt-3" v-if="user && user.range">
                    <div class="container-flex mb-2" style="justify-content: space-between;">
                        <span>
                            {{ user.range.name }}
                        </span>
                        <div >
                            ${{ formatCurrency(user.sold) }} of ${{ formatCurrency(user.range.quantity) }} ({{ calcPercentage(user.sold, user.range.quantity) }}%)
                        </div>
                    </div>
                    <b-progress variant="info" :value="calcPercentage(user.sold, user.range.quantity)" max="100" show-progress></b-progress>
                </b-card>
            </div>
        </div>
        <b-modal centered size="lg" title="Withdraw earns" hide-footer v-model="isWithdrawing">
            <div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <span>Quantity to Withdraw</span>
                        <b-form-input v-model="withdraw.quantity" />
                    </div>
                    <div class="col-12 col-md-6">
                        <span>Wallet to withdraw</span>
                        <b-form-input onlyread readonly v-model="withdraw.wallet" />
                    </div>
                </div>
                <b-alert @click="toProfile()" class="w-100 text-center mt-4 click" variant="primary" show>
                    <div>Para modificar tu wallet de retiro haz click aquí</div>
                </b-alert>
                <div class="text-right mt-4">
                    <b-button variant="primary" size="lg" class="mr-3">
                        Cancel
                    </b-button>
                    <b-button variant="primary" size="lg" @click="onSubmit" :disabled="withdraw.quantity > user.available">
                        Request withdraw
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
export default {
    data () {
        return {
            isWithdrawing: false,
            withdraw: {
                quantity: null,
                wallet_id: null
            },
            isFriday: false
        }
    },
    created () {
        this.isFriday = moment().format('ddd');
         this.getUserInfo().then(() => {
            this.withdraw.quantity = this.user.available
            this.withdraw.wallet = this.user.wallet
        })
    },
    methods: {
        ...mapActions('wallet', ['getWallets']),
        ...mapActions('auth', ['getUserInfo']),
        ...mapActions('withdraw', ['storeWithdraw']),
        formatCurrency (x) {
            if(x != null && x >= 0) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return 0
            }
        },
        calcPercentage (sold, quantity) {

            if(sold > 0) {
                let perce = (sold * 100) / quantity

                return perce.toFixed(2)
            } else {
                return 0
            }
        },
        onSubmit() {
            this.loading = true
            this.storeWithdraw(this.withdraw).then(() => {
                this.getUserInfo()
                this.loading = false
                this.isWithdrawing = false
                openNotification()
                location.reload()
            })
        },
        toProfile() {
            this.isWithdrawing = false
            this.$router.push({name: 'Profile'})
        }
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('wallet', ['wallets'])
    }
}
</script>