<template>
    <b-card class="bg-blur">
        <h2 class="mt-1">
            Last 20 transactions
        </h2>
        <b-table v-if="loading == false" class="mt-3" responsive :items="transactions" :fields="fields">
            <template #cell(description)="row">
                {{ row.item.description }}
            </template>
            <template #cell(date)="row">
                {{ formatDate(row.item.created_at) }}
            </template>
            <template #cell(quantity)="row">
                {{ row.item.quantity }}
            </template>
            <template #cell(type)="row">
                {{ row.item.type == 1 ? 'IN' : 'OUT' }}
            </template>
        </b-table>
        <Loading type="table" v-else/>
    </b-card>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
export default {
    data () {
        return {
            loading: false,
            fields: [
                { key: 'description', label: 'Description' },
                { key: 'quantity', label: 'Quantity' },
                { key: 'type', label: 'Type' },
                { key: 'date', label: 'Date' }
            ],
        }
    },
    created () {
        this.getData()
    },
    methods: {
        ...mapActions('cart', ['getTransactions']),
        getData () {
            this.loading = true
            this.getTransactions().then(() =>{
                this.loading = false
            })
        },
        formatDate(date) {
            return moment(date).format('Y-MM-DD')
        }
    },
    computed: {
        ...mapState('cart', ['transactions'])
    }
}
</script>