<template>
    <div>
        <Comissions />
        <Transactions class="mt-5"/>
    </div>
</template>
<script>
import Comissions from './Comissions.vue'
import Transactions from './Transactions.vue'
export default {
    components: {
        Comissions,
        Transactions
    }
}
</script>